import React, { useEffect, useState } from 'react'
import "./Styles/Home.scss"
import TypeWriter from 'typewriter-effect'

import ImageSlider from '../Components/ImageSlider/ImageSlider'
import { SliderData } from '../Components/ImageSlider/SliderData'
import { HashLink } from 'react-router-hash-link'
import SponsorCard from '../Components/SponsorCard'
import PFP from '../Assets/FTC/Thumbnail.png'
import Adit from '../Assets/Home/Cabby/Adit.png'
import Akshita from '../Assets/Home/Cabby/Akshita.png'
import Aleena from '../Assets/Home/Cabby/Aleena.png'
import Aruhum from '../Assets/Home/Cabby/Aruhum.png'
import Ash from '../Assets/Home/Cabby/Ash.png'
import Chris from '../Assets/Home/Cabby/Chris.png'
import Jayden from '../Assets/Home/Cabby/Jayden.png'
import Jeffrey from '../Assets/Home/Cabby/Jeffrey.png'
import Jorlin from '../Assets/Home/Cabby/Jorlin.png'
import Millan from '../Assets/Home/Cabby/Millan.png'
import Mohana from '../Assets/Home/Cabby/Mohana.png'
import Mohnish from '../Assets/Home/Cabby/Mohnish.png'
import Nairrit from '../Assets/Home/Cabby/Nairrit.png'
import Nathaniel from '../Assets/Home/Cabby/Nathaniel.png'
import Perry from '../Assets/Home/Cabby/Perry.png'
import Sid from '../Assets/Home/Cabby/Sid.png'
import Siddhant from '../Assets/Home/Cabby/Siddhant.png'
import Sofia from '../Assets/Home/Cabby/Sofia.png'
import Taiga from '../Assets/Home/Cabby/Taiga.png'
import Arun from '../Assets/Home/Cabby/arun.png'



const Home = () => {

  const [cabinetJSON, setCabinetJSON] = useState(null)

  useEffect(() => {
    fetch('https://script.google.com/macros/s/AKfycbyXfNUcLe0vfZiL4ioFljro4UHxw8Ot4Z5I461RdupuFfRATXm-nNii3NYHLL5PfJA/exec')
    .then(response => response.json())
    .then(data => setCabinetJSON(data))
  }, [])

  return (
    <>
      <div className='home-one'>
        <h1>Whitney High School Robotics</h1>
        <TypeWriter 
          options={{
            strings: ["Welcome to WHS Robotics!", "We are the engineers of the future...", "Building a community that will last a lifetime..."],
            autoStart: true,
            loop: true
          }}
        />          
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScDs1bHZPJiC-n-jgMxwI7G4mBH846H6uYGpcY8Dw2oo_N1yA/viewform">Sign up for our club!</a>
        <HashLink smooth to="#mission" data-aos="fade-up">Learn more about our mission</HashLink>
      </div>
      <div className='home-two' id="mission">
        <h1>Our Mission</h1>
        <hr />
        <div className='row computer'>
          <div className='column'>
            <p>WHS Robotics originated in 2002 as an official club of Whitney High School in Cerritos, California. Whitney High School is home to academically gifted students throughout the ABC Unified School District, serving students from grades 7 to 12 and has gained a reputation as the top school in California and one of the finest in the nation. Currently our club administers multiple competition teams including FIRST Tech Challenge (FTC) Team #542, and FIRST LEGO League (FLL) team 3204. We also perform outreach events at a variety of locations in our community, including local libraries and elementary schools, with the goal of promoting STEM and robotics.</p>
            <p><b>You can get more details on each specific part of our club by exploring our site through the navigation bar above!</b></p>
          </div>
          <ImageSlider slides={SliderData} fadeType="fade-left"/>
        </div>
        <div className='mobile'>
          <p>WHS Robotics originated in 2002 as an official club of Whitney High School in Cerritos, California. Whitney High School is home to academically gifted students throughout the ABC Unified School District, serving students from grades 7 to 12 and has gained a reputation as the top school in California and one of the finest in the nation. Currently our club administers multiple competition teams including FIRST Tech Challenge (FTC) Team #542, and FIRST LEGO League (FLL) team 3204. We also perform outreach events at a variety of locations in our community, including local libraries and elementary schools, with the goal of promoting STEM and robotics.</p>
          <p><b>You can get more details on each specific part of our club by exploring our site through the navigation bar above!</b></p>
          <ImageSlider slides={SliderData} fadeType="fade-up"/>
        </div>
      </div>
      <div className='home-two'>
        <h1>School and Club History</h1>
        <hr />
        <div className='row computer'>
          <div className='column'>
            <p>WHS Robotics is a club at Whitney High School, the #1 High School in California, and located in Cerritos. Our school serves grades 7th to 12th in the ABC Unified School District. Whitney High School is an academically driven school, earning the Blue Ribbon and California Distinguished School awards multiple times. For numerous years, our school has been named #3 in California and ranked in the Top 30 in the nation.</p>
            <p>Our club is student-run and has had teams competing in FIRST Robotics Challenge, FIRST Lego League, FIRST Tech Challenge, VEX IQ, and VEX Robotics Competition. Our club now consists of FTC and FLL teams. Many of our teams have competed and placed in international competitions. Our club originated in 2002 with the help of our lovely, retired club advisors, Ms. Sandra Bruesch and Ms. Marilou McSherry.</p>
          </div>
        </div>
        <div className='mobile'>
          <p>WHS Robotics is a club at Whitney High School, the #2 High School in California, and located in Cerritos. Our school serves grades 7th to 12th in the ABC Unified School District. Whitney High School is an academically driven school, earning the Blue Ribbon and California Distinguished School awards multiple times. For numerous years, our school has been named #1 in California and ranked in the Top 15 in the nation.</p>
          <p>Our club is student-run and has had teams competing in FIRST Robotics Challenge, FIRST Lego League, FIRST Tech Challenge, VEX IQ, and VEX Robotics Competition. Our club now consists of FTC and FLL teams. Many of our teams have competed and placed in international competitions. Our club originated in 2002 with the help of our lovely, retired club advisors, Ms. Sandra Bruesch and Ms. Marilou McSherry.</p>
        </div>
        <HashLink smooth to="#team" data-aos="fade-up">Meet the team and our advisors!</HashLink>
      </div>
      <div className='home-three' id="team">
        <h1>Meet the team</h1>
        <hr />
        <div className='flex-grid web' data-aos="fade-up">
            <h2>Club Leadership Team</h2>
            <div className='col'>
            <SponsorCard logo={Perry} title="Perry Han" desc="Loves to spend his free time programming, watching puzzle/heist movies, building stuff, playing puzzle games and RDR, training new members." role="Co-President"/>
              <SponsorCard logo={Aleena} title="Aleena Kang" desc="Loves to online shop for things she'll never buy, slaving over CAD, listening to music (yet is unable to name a favorite song), playing online games with friends (like valorant), and scrolling through Tiktok!" role="Co-President"/>
              <SponsorCard logo={Sid} title="Sid Shah" desc="Spends his free time playing video games, coding, and watching math on YouTube." role="Co-Vice President"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Sofia} title="Sofia Nowell" desc="Enjoys listening to music or podcasts or reading cool mystery books." role="Co-Vice Prsident"/>
              <SponsorCard logo={Chris} title="Christine Liu" desc="Does photocard trading or, alternatively, online shopping." role="Secretary"/>
              <SponsorCard logo={Ash} title="Ash Profeta" desc="Likes to walk around and listen to music and send/receive instagram posts or reels of silly cats to/from friends." role="Secretary"/>
              <SponsorCard logo={Nairrit} title="Nairrit Sengupta" desc="Loves the color dark red (like mahogany). Finally, he fills his free time playing copious amounts of chess." role="Co-Treasurer"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Siddhant} title="Siddhant Khapra" desc="Loves doing robotics, playing tennis, and reading Wikipedia." role="Co-Treasurer"/>
              <SponsorCard logo={Jeffrey} title="Jeffrey Liao" desc="Loves playing games, talking with friends, designing (furry) cosplay stuff, and more!" role="FTC Captain"/>
              <SponsorCard logo={Akshita} title="Akshita Shivakumar" desc="Likes to sleep, draw graffiti, rock climb, or read." role="Apprentice FTC Captain"/>
              <SponsorCard logo={Jorlin} title="Jorlin Chai" desc="Enjoys playing competitive Pokemon or watching an unholy amount of anime." role="Head FLL Mentor"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Arun} title="Arun Nambiar" desc="In his free time he loves playing different sports, specifically tennis." role="Head FLL Mentor"/>
              <SponsorCard logo={Millan} title="Millan Hamilton" desc="His favorite color is Navy Blue. Finally, in his free time he enjoys learning new things in the STEM field." role="3204 FLL Captain"/>
              <SponsorCard logo={Nathaniel} title="Nathaniel Soco" desc="Spends his free time getting better at making Minecraft textures, playing a lot of video games (Super Smash Brothers, Minecraft, Clash Royale), and use most of the time on weekdays with Robotics!" role="20157 FLL Captain"/>
              <SponsorCard logo={Aruhum} title="Aruhum Jain" desc="His favorite color is blue. Last, but not least, in his free time he enjoys code, playing video games, and watching movies with friends." role="Director of Engineering"/>
            </div>
            <div className='col'>
              <SponsorCard logo={Mohana} title="Mohana Sai" desc="Her favorite color is pink. Finally, in her free time she is typically studying, relaxing, running, and watching TV." role="Apprentice of Engineering"/>
              <SponsorCard logo={Taiga} title="Jaktaiga Thomas" desc="His favorite color is light blue. Finally, in his free time, he enjoys watching youtube of various topics and anime!" role="Director of Activities"/>
              <SponsorCard logo={Mohnish} title="Mohnish Sivakumar" desc="Spends his free time playing volleyball, hanging out with friends, and binging KPOP videos." role="Director of Technology"/>
              <SponsorCard logo={Adit} title="Adit Shah" desc="His favorite color is purple. Finally, in his free time he likes to play video games!" role="Historian"/>
            </div>
            <div className='col'>

            <SponsorCard logo={Jayden} title="Jayden Hong" desc="His favorite color is mint (#a3dec9). Finally, he spends his free time having fun!" role="Director of Publicity"/>
            </div>

          </div>
          <div className='flex-grid mobile1' data-aos="fade-up">
            <h2>Club Leadership Team</h2>
            <div className='col'>
              <SponsorCard logo={Perry} title="Perry Han" desc="Loves to spend his free time programming, watching puzzle/heist movies, building stuff, playing puzzle games and RDR, training new members." role="Co-President"/>
              <SponsorCard logo={Aleena} title="Aleena Kang" desc="Loves to online shop for things she'll never buy, slaving over CAD, listening to music (yet is unable to name a favorite song), playing online games with friends (like valorant), and scrolling through Tiktok!" role="Co-President"/>
              <SponsorCard logo={Sid} title="Sid Shah" desc="Spends his free time playing video games, coding, and watching math on YouTube." role="Co-Vice President"/>
            </div>
            <div className='col'>
              <SponsorCard logo={Sofia} title="Sofia Nowell" desc="Enjoys listening to music or podcasts or reading cool mystery books." role="Co-Vice Prsident"/>
              <SponsorCard logo={Chris} title="Christine Liu" desc="Does photocard trading or, alternatively, online shopping." role="Secretary"/>
              <SponsorCard logo={Ash} title="Ash Profeta" desc="Likes to walk around and listen to music and send/receive instagram posts or reels of silly cats to/from friends." role="Secretary"/>
            </div>
            <div className='col'>
              <SponsorCard logo={Nairrit} title="Nairrit Sengupta" desc="Loves the color dark red (like mahogany). Finally, he fills his free time playing copious amounts of chess." role="Co-Treasurer"/>
              <SponsorCard logo={Siddhant} title="Siddhant Khapra" desc="Loves doing robotics, playing tennis, and reading Wikipedia." role="Co-Treasurer"/>
              <SponsorCard logo={Jeffrey} title="Jeffrey Liao" desc="Loves playing games, talking with friends, designing (furry) cosplay stuff, and more!" role="FTC Captain"/>
            </div>
            <div className='col'>
              <SponsorCard logo={Akshita} title="Akshita Shivakumar" desc="Likes to sleep, draw graffiti, rock climb, or read." role="Apprentice FTC Captain"/>
              <SponsorCard logo={Jorlin} title="Jorlin Chai" desc="Enjoys playing competitive Pokemon or watching an unholy amount of anime." role="Head FLL Mentor"/>
              <SponsorCard logo={Arun} title="Arun Nambiar" desc="In his free time he loves playing different sports, specifically tennis." role="Head FLL Mentor"/>
            </div>
            <div className='col'>
              <SponsorCard logo={Millan} title="Millan Hamilton" desc="His favorite color is Navy Blue. Finally, in his free time he enjoys learning new things in the STEM field." role="3204 FLL Captain"/>
              <SponsorCard logo={Nathaniel} title="Nathaniel Soco" desc="Spends his free time getting better at making Minecraft textures, playing a lot of video games (Super Smash Brothers, Minecraft, Clash Royale), and use most of the time on weekdays with Robotics!" role="20157 FLL Captain"/>
              <SponsorCard logo={Aruhum} title="Aruhum Jain" desc="His favorite color is blue. Last, but not least, in his free time he enjoys code, playing video games, and watching movies with friends." role="Director of Engineering"/>
            </div>
            <div className='col'>
              <SponsorCard logo={Mohana} title="Mohana Sai" desc="Her favorite color is pink. Finally, in her free time she is typically studying, relaxing, running, and watching TV." role="Apprentice of Engineering"/>
              <SponsorCard logo={Taiga} title="Jaktaiga Thomas" desc="His favorite color is light blue. Finally, in his free time, he enjoys watching youtube of various topics and anime!" role="Director of Activities"/>
              <SponsorCard logo={Mohnish} title="Mohnish Sivakumar" desc="Spends his free time playing volleyball, hanging out with friends, and binging KPOP videos." role="Director of Technology"/>
            </div>
            <div className='col'>
              <SponsorCard logo={Adit} title="Adit Shah" desc="His favorite color is purple. Finally, in his free time he likes to play video games!" role="Historian"/>
              <SponsorCard logo={Jayden} title="Jayden Hong" desc="His favorite color is mint (#a3dec9). Finally, he spends his free time having fun!" role="Director of Publicity"/>
            </div>
          </div>
          <div className='flex-grid mobile2' data-aos="fade-up">
            <h2>Club Leadership Team</h2>
            <div className='col'>
              <SponsorCard logo={Perry} title="Perry Han" desc="Loves to spend his free time programming, watching puzzle/heist movies, building stuff, playing puzzle games and RDR, training new members." role="Co-President"/>
              <SponsorCard logo={Aleena} title="Aleena Kang" desc="Loves to online shop for things she'll never buy, slaving over CAD, listening to music (yet is unable to name a favorite song), playing online games with friends (like valorant), and scrolling through Tiktok!" role="Co-President"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Sid} title="Sid Shah" desc="Spends his free time playing video games, coding, and watching math on YouTube." role="Co-Vice President"/>
            <SponsorCard logo={Sofia} title="Sofia Nowell" desc="Enjoys listening to music or podcasts or reading cool mystery books." role="Co-Vice Prsident"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Chris} title="Christine Liu" desc="Does photocard trading or, alternatively, online shopping." role="Secretary"/>
            <SponsorCard logo={Ash} title="Ash Profeta" desc="Likes to walk around and listen to music and send/receive instagram posts or reels of silly cats to/from friends." role="Secretary"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Nairrit} title="Nairrit Sengupta" desc="Loves the color dark red (like mahogany). Finally, he fills his free time playing copious amounts of chess." role="Co-Treasurer"/>
            <SponsorCard logo={Siddhant} title="Siddhant Khapra" desc="Loves doing robotics, playing tennis, and reading Wikipedia." role="Co-Treasurer"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Jeffrey} title="Jeffrey Liao" desc="Loves playing games, talking with friends, designing (furry) cosplay stuff, and more!" role="FTC Captain"/>
            <SponsorCard logo={Akshita} title="Akshita Shivakumar" desc="Likes to sleep, draw graffiti, rock climb, or read." role="Apprentice FTC Captain"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Jorlin} title="Jorlin Chai" desc="Enjoys playing competitive Pokemon or watching an unholy amount of anime." role="Head FLL Mentor"/>
            <SponsorCard logo={Arun} title="Arun Nambiar" desc="In his free time he loves playing different sports, specifically tennis." role="Head FLL Mentor"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Arun} title="Arun Nambiar" desc="In his free time he loves playing different sports, specifically tennis." role="Head FLL Mentor"/>
            <SponsorCard logo={Millan} title="Millan Hamilton" desc="His favorite color is Navy Blue. Finally, in his free time he enjoys learning new things in the STEM field." role="3204 FLL Captain"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Nathaniel} title="Nathaniel Soco" desc="Spends his free time getting better at making Minecraft textures, playing a lot of video games (Super Smash Brothers, Minecraft, Clash Royale), and use most of the time on weekdays with Robotics!" role="20157 FLL Captain"/>
            <SponsorCard logo={Aruhum} title="Aruhum Jain" desc="His favorite color is blue. Last, but not least, in his free time he enjoys code, playing video games, and watching movies with friends." role="Director of Engineering"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Mohana} title="Mohana Sai" desc="Her favorite color is pink. Finally, in her free time she is typically studying, relaxing, running, and watching TV." role="Apprentice of Engineering"/>
            <SponsorCard logo={Taiga} title="Jaktaiga Thomas" desc="His favorite color is light blue. Finally, in his free time, he enjoys watching youtube of various topics and anime!" role="Director of Activities"/>

            </div>
            <div className='col'>
            <SponsorCard logo={Mohnish} title="Mohnish Sivakumar" desc="Spends his free time playing volleyball, hanging out with friends, and binging KPOP videos." role="Director of Technology"/>
            <SponsorCard logo={Adit} title="Adit Shah" desc="His favorite color is purple. Finally, in his free time he likes to play video games!" role="Historian"/>
            </div>
            <div className='col'>
            <SponsorCard logo={Jayden} title="Jayden Hong" desc="His favorite color is mint (#a3dec9). Finally, he spends his free time having fun!" role="Director of Publicity"/>
            </div>

          </div>
          <div className='flex-grid mobile3' data-aos="fade-up">
            
            <h2>Club Leadership Team</h2>
            <SponsorCard logo={Perry} title="Perry Han" desc="Loves to spend his free time programming, watching puzzle/heist movies, building stuff, playing puzzle games and RDR, training new members." role="Co-President"/>
            <SponsorCard logo={Aleena} title="Aleena Kang" desc="Loves to online shop for things she'll never buy, slaving over CAD, listening to music (yet is unable to name a favorite song), playing online games with friends (like valorant), and scrolling through Tiktok!" role="Co-President"/>

            <SponsorCard logo={Sid} title="Sid Shah" desc="Spends his free time playing video games, coding, and watching math on YouTube." role="Co-Vice President"/>
            <SponsorCard logo={Sofia} title="Sofia Nowell" desc="Enjoys listening to music or podcasts or reading cool mystery books." role="Co-Vice Prsident"/>

            <SponsorCard logo={Chris} title="Christine Liu" desc="Does photocard trading or, alternatively, online shopping." role="Secretary"/>
            <SponsorCard logo={Ash} title="Ash Profeta" desc="Likes to walk around and listen to music and send/receive instagram posts or reels of silly cats to/from friends." role="Secretary"/>
            
            <SponsorCard logo={Nairrit} title="Nairrit Sengupta" desc="Loves the color dark red (like mahogany). Finally, he fills his free time playing copious amounts of chess." role="Co-Treasurer"/>
            <SponsorCard logo={Siddhant} title="Siddhant Khapra" desc="Loves doing robotics, playing tennis, and reading Wikipedia." role="Co-Treasurer"/>

            <SponsorCard logo={Jeffrey} title="Jeffrey Liao" desc="Loves playing games, talking with friends, designing (furry) cosplay stuff, and more!" role="FTC Captain"/>
            <SponsorCard logo={Akshita} title="Akshita Shivakumar" desc="Likes to sleep, draw graffiti, rock climb, or read." role="Apprentice FTC Captain"/>

            <SponsorCard logo={Jorlin} title="Jorlin Chai" desc="Enjoys playing competitive Pokemon or watching an unholy amount of anime." role="Head FLL Mentor"/>
            <SponsorCard logo={Arun} title="Arun Nambiar" desc="In his free time he loves playing different sports, specifically tennis." role="Head FLL Mentor"/>

            <SponsorCard logo={Arun} title="Arun Nambiar" desc="In his free time he loves playing different sports, specifically tennis." role="Head FLL Mentor"/>
            <SponsorCard logo={Millan} title="Millan Hamilton" desc="His favorite color is Navy Blue. Finally, in his free time he enjoys learning new things in the STEM field." role="3204 FLL Captain"/>

            <SponsorCard logo={Nathaniel} title="Nathaniel Soco" desc="Spends his free time getting better at making Minecraft textures, playing a lot of video games (Super Smash Brothers, Minecraft, Clash Royale), and use most of the time on weekdays with Robotics!" role="20157 FLL Captain"/>
            <SponsorCard logo={Aruhum} title="Aruhum Jain" desc="His favorite color is blue. Last, but not least, in his free time he enjoys code, playing video games, and watching movies with friends." role="Director of Engineering"/>

            <SponsorCard logo={Mohana} title="Mohana Sai" desc="Her favorite color is pink. Finally, in her free time she is typically studying, relaxing, running, and watching TV." role="Apprentice of Engineering"/>
            <SponsorCard logo={Taiga} title="Jaktaiga Thomas" desc="His favorite color is light blue. Finally, in his free time, he enjoys watching youtube of various topics and anime!" role="Director of Activities"/>

            <SponsorCard logo={Mohnish} title="Mohnish Sivakumar" desc="Spends his free time playing volleyball, hanging out with friends, and binging KPOP videos." role="Director of Technology"/>
            <SponsorCard logo={Adit} title="Adit Shah" desc="His favorite color is purple. Finally, in his free time he likes to play video games!" role="Historian"/>

            <SponsorCard logo={Jayden} title="Jayden Hong" desc="His favorite color is mint (#a3dec9). Finally, he spends his free time having fun!" role="Director of Publicity"/>
          </div>
          <div className='advisors'>
            <h2>Advisors</h2>
            <p>Matthew Johnson | Steven Ou | Kimberly Suzuki | Eamon Marchant | Paul Bender</p>
          </div>
      </div>
    </>
  )
}

export default Home